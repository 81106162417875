export default [
  [48.8143312, 2.2781214],
  [48.8142754, 2.2781937],
  [48.8144191, 2.2793264],
  [48.8144769, 2.279501],
  [48.814612, 2.2803937],
  [48.8154139, 2.2845136],
  [48.8159414, 2.2863604],
  [48.8159991, 2.2863701],
  [48.8160505, 2.2865835],
  [48.8160249, 2.2866612],
  [48.816417, 2.2877876],
  [48.8168006, 2.2887938],
  [48.81686, 2.2889963],
  [48.8168938, 2.2890017],
  [48.8170808, 2.2894639],
  [48.8172669, 2.2899271],
  [48.8174247, 2.2902929],
  [48.8176136, 2.29071],
  [48.8177046, 2.2909157],
  [48.8177489, 2.2910164],
  [48.8177932, 2.2911225],
  [48.8177859, 2.2911301],
  [48.817781, 2.2911178],
  [48.8177689, 2.2911296],
  [48.8178108, 2.2912249],
  [48.8178204, 2.2912147],
  [48.8181946, 2.2920083],
  [48.818214, 2.2920149],
  [48.8183558, 2.2922852],
  [48.8183559, 2.2923108],
  [48.8185332, 2.2926411],
  [48.8187188, 2.2929631],
  [48.8189545, 2.2933336],
  [48.8194323, 2.2940759],
  [48.8195388, 2.2942227],
  [48.820339, 2.2954622],
  [48.8208895, 2.2961355],
  [48.8210137, 2.2962884],
  [48.8212889, 2.2965918],
  [48.8214474, 2.2967809],
  [48.8216581, 2.297034],
  [48.8218076, 2.2972049],
  [48.8224511, 2.297992],
  [48.822456, 2.2979839],
  [48.8234646, 2.299244],
  [48.8251252, 2.3013179],
  [48.8250223, 2.3017791],
  [48.8249028, 2.3023384],
  [48.8245257, 2.3039805],
  [48.8245201, 2.3040035],
  [48.824512, 2.3040439],
  [48.8244931, 2.3041229],
  [48.8244361, 2.3043777],
  [48.8241052, 2.305836],
  [48.8239892, 2.3063461],
  [48.8239535, 2.3065022],
  [48.8238869, 2.3068117],
  [48.823528, 2.308423],
  [48.8231256, 2.3102584],
  [48.8228465, 2.3115241],
  [48.8225108, 2.3130139],
  [48.8224269, 2.3133857],
  [48.8224326, 2.3133907],
  [48.8223339, 2.3138],
  [48.8222632, 2.314133],
  [48.8219452, 2.3136487],
  [48.8217428, 2.3133804],
  [48.8212242, 2.3126427],
  [48.8207583, 2.3120019],
  [48.8202611, 2.3113087],
  [48.8201313, 2.3111305],
  [48.8198946, 2.3108376],
  [48.8198388, 2.310761],
  [48.8196983, 2.310575],
  [48.8196674, 2.3105335],
  [48.8195043, 2.3103091],
  [48.8194425, 2.3102264],
  [48.8193516, 2.3101093],
  [48.8190048, 2.3096497],
  [48.8188938, 2.3095014],
  [48.8186906, 2.3092294],
  [48.818317, 2.3087299],
  [48.8182055, 2.3085789],
  [48.8181799, 2.3085434],
  [48.8178091, 2.3080391],
  [48.8177719, 2.3079884],
  [48.8176965, 2.3078858],
  [48.817596, 2.3077492],
  [48.8175696, 2.3077134],
  [48.8174687, 2.3075765],
  [48.8171492, 2.3071415],
  [48.816795, 2.3066523],
  [48.8167239, 2.306554],
  [48.8167046, 2.3065276],
  [48.8164932, 2.3062333],
  [48.8162414, 2.305857],
  [48.8161758, 2.3057515],
  [48.8160252, 2.3055414],
  [48.815697, 2.305062],
  [48.8152481, 2.3044225],
  [48.8150984, 2.304222],
  [48.8148631, 2.3039291],
  [48.8146328, 2.3036427],
  [48.814584, 2.3035822],
  [48.8142887, 2.3032158],
  [48.81424, 2.3031548],
  [48.814181, 2.3030781],
  [48.8140652, 2.3029391],
  [48.8139894, 2.3028433],
  [48.8138963, 2.302734],
  [48.8138195, 2.3026438],
  [48.8136607, 2.3024496],
  [48.8133245, 2.3020834],
  [48.8130702, 2.301803],
  [48.8129972, 2.3017303],
  [48.8129282, 2.3016617],
  [48.8128875, 2.3016166],
  [48.8128422, 2.3015692],
  [48.8128208, 2.3015461],
  [48.8128021, 2.3015278],
  [48.8123667, 2.3010985],
  [48.8122102, 2.3009404],
  [48.812145, 2.3008682],
  [48.8120212, 2.3007592],
  [48.8119707, 2.3007086],
  [48.8118926, 2.3006334],
  [48.81165, 2.3003658],
  [48.8116193, 2.3003313],
  [48.8115136, 2.3002322],
  [48.8113242, 2.3000165],
  [48.8109583, 2.2995997],
  [48.8121353, 2.298007],
  [48.8116522, 2.2970907],
  [48.8114798, 2.2967518],
  [48.8112111, 2.2961931],
  [48.8105294, 2.2947842],
  [48.8113001, 2.2942459],
  [48.8118428, 2.293867],
  [48.8119002, 2.2938308],
  [48.8118842, 2.293766],
  [48.8118785, 2.2936975],
  [48.8118829, 2.2936288],
  [48.8118976, 2.2935634],
  [48.8118209, 2.2935097],
  [48.8113861, 2.2932059],
  [48.8110546, 2.2929661],
  [48.810947, 2.2928921],
  [48.8105259, 2.2926028],
  [48.8097083, 2.2920085],
  [48.8097056, 2.2915389],
  [48.809705, 2.2913832],
  [48.8096919, 2.2904591],
  [48.8096461, 2.2904547],
  [48.8096164, 2.290457],
  [48.8095185, 2.2904508],
  [48.8093355, 2.2904637],
  [48.8093344, 2.2904583],
  [48.8089222, 2.2904732],
  [48.8094363, 2.2892776],
  [48.8094684, 2.289203],
  [48.8095179, 2.2890875],
  [48.8095345, 2.2890491],
  [48.8096413, 2.2887948],
  [48.8097647, 2.2884396],
  [48.8098946, 2.2880508],
  [48.8100104, 2.2877051],
  [48.8100936, 2.2874532],
  [48.810569, 2.2853792],
  [48.8105884, 2.2852942],
  [48.8106513, 2.285046],
  [48.8106045, 2.2848773],
  [48.8105259, 2.2845944],
  [48.810217, 2.2834824],
  [48.8102925, 2.2834287],
  [48.8105575, 2.2832506],
  [48.8106693, 2.281866],
  [48.8106785, 2.2817988],
  [48.8106949, 2.2816764],
  [48.8107356, 2.2814056],
  [48.8109067, 2.2798973],
  [48.810909, 2.2798774],
  [48.8109812, 2.2799362],
  [48.8112126, 2.2792405],
  [48.81125, 2.2790984],
  [48.8112943, 2.2789746],
  [48.8113447, 2.2787993],
  [48.8114316, 2.2784911],
  [48.8115675, 2.2781925],
  [48.8116387, 2.2780402],
  [48.811709, 2.2778973],
  [48.8119288, 2.2774592],
  [48.8119765, 2.2773528],
  [48.8124412, 2.2760386],
  [48.8124183, 2.2760114],
  [48.8127444, 2.2753934],
  [48.8127729, 2.2754278],
  [48.8134649, 2.2744027],
  [48.8143312, 2.2781214],
];
