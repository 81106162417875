<template>
  <Teleport to="#app">
    <transition name="fade-fast">
      <div class="modal" role="dialog">
        <div class="modal-overlay" @click="allow_close ? $emit('close') : ''" />
        <div class="modal-inner">
          <div class="modal-header" v-if="$slots.header">
            <div>
              <slot name="header"></slot>
            </div>
            <button
              v-if="allow_close"
              class="closeButton"
              @click="$emit('close')"
              aria-label="Close"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="23.7px"
                height="23.7px"
                viewBox="0 0 23.7 23.7"
                style="stroke: currentColor; stroke-width: 2px"
                xml:space="preserve"
              >
                <line class="st0" x1="1.1" y1="1.1" x2="22.6" y2="22.6" />
                <line class="st0" x1="22.6" y1="1.1" x2="1.1" y2="22.6" />
              </svg>
            </button>
          </div>

          <div class="_body">
            <slot name="body"></slot>
          </div>

          <div class="_buttons">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script>
import Teleport from "vue2-teleport";

export default {
  name: "BaseModal",
  props: {
    allow_close: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Teleport,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    document.addEventListener("keydown", this.escClose);
    this.$root.modal_is_open = true;
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.escClose);
    this.$root.modal_is_open = false;
  },
  watch: {},
  computed: {},
  methods: {
    escClose(e) {
      if (!this.allow_close) return false;
      if (e.keyCode == 27) this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;

  overflow: auto;

  inset: 0;
  z-index: 1001;
  padding: 0;
}
.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 0;
  background-color: white;
  // background-color: black;
  background-color: rgba(241, 241, 241, 0.8);
  background-color: var(--color-cream);
  opacity: 0.95;
}

.modal-inner {
  position: relative;
  background: white;

  width: var(--modal-maxwidth);
  max-width: 100%;
  margin: auto;
  padding: calc(var(--spacing) / 2) 0;

  > * {
    margin: calc(var(--spacing) * 1) calc(var(--spacing) * 1);
  }
}
.modal-header {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  // > * {
  //   display: flex;
  //   align-items: center;
  //   gap: calc(var(--spacing) / 2);
  // }
  // border-bottom: 2px solid black;
}

.closeButton {
  position: absolute;
  right: 0em;
  top: 0em;
  z-index: 1;
}
</style>
