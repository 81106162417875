<template>
  <BaseModal
    :allow_close="$root.gps_locations.length > 0"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <div class="_header">
        <h1>Alice à Malakoff</h1>
        <h2>Le parcours Humpty Dumpty</h2>
      </div>
      <!-- <h3>Bienvenue sur le parcours Humpty Dumpty</h3> -->
    </template>

    <template v-slot:body>
      <details>
        <summary>Informations & crédits</summary>
        <blockquote>
          « Le terrier présentait une pente si brusque et si raide qu'Alice
          n'eut même pas le temps de songer à s'arrêter avant de se sentir
          tomber dans un puits apparemment très profond. Soit que le puits fût
          très profond, soit que Alice tombât très lentement, elle s’aperçût
          qu'elle avait le temps, tout en descendant, de regarder autour d'elle
          et de se demander ce qui allait se passer. »
        </blockquote>
        <p>
          Alice nous entraîne avec elle dans son pays des merveilles, remplis de
          personnages nous confrontant aux paradoxes, à l'absurde ou au bizarre.
          Chacun peut avoir une vision, une compréhension et une réflexion
          autour de cet univers. En plus du côté artistique de l’œuvre
          elle-même, l’auteur nous permet d’aborder des thèmes aussi variés que
          le bégaiement, l’autisme, l’exclusion, les différences, …
          <br />
          <br />
          Laissez-nous vous guider à travers l’univers fascinant de Lewis
          Caroll. Suivez le lapin blanc et découvrez ou redécouvrez le Pays des
          Merveilles en suivant notre parcours musical à travers la ville de
          Malakoff.
        </p>
        <br />
        <div class="_infoContent">
          <span>Histoire</span>
          <span>Jérôme Hubert-Réjon, Michaël Bouvard et Sylvain Bouard</span>
        </div>
        <div class="_infoContent">
          <span>Création sonore</span>
          <a href="https://tsukuboshi.wordpress.com/" target="_blank">
            Laurent Guérel / Tsuku Boshi Rec
          </a>
        </div>
        <div class="_infoContent">
          <span>Création interactive/code</span>
          <a href="https://louiseveillard.com/" target="_blank">
            Louis Eveillard
          </a>
        </div>
        <div class="_infoContent">
          <span>Création graphique</span>
          <a href="https://www.emiliecoquard.fr/" target="_blank">
            Émilie Coquard
          </a>
        </div>
        <div class="_infoContent">
          <span>Technologies et bibliothèques open-source employées</span>
          <span
            ><a href="https://www.openstreetmap.org/" target="_blank">
              OpenStreetMap </a
            >, <a href="https://leafletjs.com/" target="_blank">Leaflet</a>,
            <a href="https://protomaps.com/" target="_blank">Protomaps</a>,
            <a
              href="https://developer.mozilla.org/fr/docs/Web/API/Geolocation_API"
              target="_blank"
              >API géolocalisation</a
            >, <a href="https://vuejs.org/" target="_blank">Vue.js</a>.
          </span>
        </div>
        <div class="_infoContent">
          <span>Famille de caractères typographiques</span>
          <a href="https://www.cnap.fr/sites/faune/" target="_blank">
            Faune, Alice Savoie / Cnap
          </a>
        </div>
        <br />
        <div class="_infoContent">
          <span> Des bugs, des retours, des questions ?</span>
          <span>
            <a href="mailto:hello@louiseveillard.com" target="_blank">
              hello@louiseveillard.com
            </a>
          </span>
        </div>
      </details>
    </template>
    <template v-slot:buttons>
      <div class="_buttons">
        <small v-if="$root.gps_locations.length > 0">
          Recommencer le parcours :
        </small>
        <button type="button" @click="longExperience">
          J’ai le temps pour faire le parcours (1h)
        </button>
        <small> Suivre le parcours comme il a été pensé </small>
        <button type="button" @click="shortExperience">
          Je suis pressé.e ou j’ai déjà fait le parcours
        </button>
        <small>Accéder directement à toutes les étapes du parcours</small>
      </div>
      <div class="_cookies">
        <small>
          Ce site utilise des cookies et la géolocalisation pour suivre votre
          parcours mais aucune information ne quitte votre appareil.
        </small>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
  props: {
    allow_close: Boolean,
  },
  components: {
    BaseModal,
  },
  methods: {
    longExperience() {
      this.$root.revealed_balises = [0];
      this.$root.openable_balises = [];
      this.$root.opened_balises = [];
      this.$root.gps_locations = [];
      this.$root.mode = "long";
      this.$emit("close");
    },
    shortExperience() {
      this.$root.mode = "short";
      Array(12)
        .fill()
        .map((a, index) => {
          if (!this.$root.revealed_balises.includes(index))
            this.$root.revealed_balises.push(index);
          if (!this.$root.openable_balises.includes(index))
            this.$root.openable_balises.push(index);
        });
      this.$root.opened_balises = [];
      this.$root.gps_locations = [];
      this.$emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
._modal {
  padding: 1rem;
}
h1 {
  margin: 0;
  margin-top: calc(var(--spacing) * -0.5);
}
h2 {
  margin: 0;
}

h3 {
  margin: 0px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

._header {
  text-align: center;
}

._buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;

  small:not(:last-child) {
    margin-bottom: var(--spacing);
  }

  button {
    padding: 0.4em 0.8em;
  }

  button,
  small {
    display: block;
    // width: 100%;
  }
}

._cookies {
  margin-top: var(--spacing);
  margin-bottom: 0;
  line-height: 1;
}

details {
  margin-bottom: calc(var(--spacing) * 2);
}

summary {
  text-align: center;
}

._infoContent {
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--fs-small);
  gap: calc(var(--spacing) / 2);
  justify-content: space-between;
  text-align: left;

  > *:last-child {
    text-align: right;
  }
}
</style>
