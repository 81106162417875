export default [
  {
    chapter: "I",
    name: "Descente dans le terrier du lapin",
    subname: "Léon",
    text: `
    Tout voyage vers le Pays des merveilles commence par un passage. Ne cherchez pas de terrier.
    Laissez-vous simplement guider par la lumière de Léon, et parcourez le sentier.<br /><br />Prenez votre temps,
    vous n’êtes pas en retard. Sentez, écoutez, observez. Peut-être apercevrez-vous une bouteille avec
    une étiquette qui vous invite à la boire. N’en faites-rien. Laissez-vous simplement porter par vos pas.
    `,
    coords: [48.82295600485516, 2.306265478494097],
    audio_file: `humpty-dumpty-1.mp3`,
    color: `yellow`,
  },
  {
    chapter: "II",
    name: "La mare de larmes",
    subname: "Parc Larousse",
    text: `
    Vous êtes toujours la même personne, mais vous avez déjà changé. Vous oubliez vos tables de
    multiplication ou votre géographie ? Vous pensez que Paris est la capitale de Londres ?<br /><br />Ne pleurez-
    pas, si vous ne voulez pas vous noyer dans vos larmes. Vous n’êtes pas aussi grand que les arbres du
    Parc, mais en vous étirant, regardez sous une feuille de ce lieu de savoir, et invoquez Pierre Larousse.
    `,
    coords: [48.82282434948758, 2.3028841366520876],
    audio_file: `humpty-dumpty-2.mp3`,
    color: `yellow`,
  },
  {
    chapter: "III",
    name: "Une course au Caucus et une longue histoire",
    subname: "Nouvelle Place Dépinoy",
    text: `
    Il pleut ? Rien ne sert de courir pour vous sécher. Il ne pleut pas ? Posez-vous sur le banc pour
    contempler ce bel arbre. Combien de vies a-t-il vu passer ? Combien de gouttes ont glissé sur ses
    feuilles ? Combien de poètes lui ont murmuré leurs vers les plus intimes ?<br /><br />Allez, ne prenez pas
    racine ! Secouez vos branches et marchez jusqu’à la prochaine étape. Le voyage commence tout
    juste.
    `,
    coords: [48.822072084462114, 2.3063888982952454],
    audio_file: `humpty-dumpty-3.mp3`,
    color: `yellow`,
  },
  {
    chapter: "IV",
    name: "Le lapin fait intervenir le petit Bill",
    subname: "Usine Clacquesin",
    text: `
    L’odeur du Clacquesin traverse les âges. Il est un peu tôt pour l’apéritif, mais vous avez soif. Vous
    décidez de vous désaltérer. Ce n’est que quand l’amertume traverse votre gorge que vous réalisez
    que vous n’aviez jamais aperçu cette bouteille.<br /><br />Déjà, vous grandissez. Votre tête se rapproche du
    plafond. Votre corps se presse contre les murs. Vite, un gâteau ! N’en mangez pas trop, vous
    deviendriez minuscule.      
    `,
    coords: [48.821118027641496, 2.310624725281102],
    audio_file: `humpty-dumpty-4.mp3`,
    color: `yellow`,
  },
  {
    chapter: "V",
    name: "Les conseils de la Chenille",
    subname: "Jardin de la Maison des Arts",
    text: `
    Mille émotions, mille pensées vous traversent. Chaque œuvre d’art est un morceau de son créateur.
    Trop de sentiments ! Au milieu de ce bouillonnement, une question vous taraude : «&nbsp;Qui suis-je ?&nbsp;».
    <br /><br />Êtes-vous la même personne qu’au début du parcours ? Avez-vous progressé, régressé, ou tout
    simplement vécu ? Vous êtes-vous métamorphosé en papillon, ou allez-vous rester pour manger des
    champignons ?      
    `,
    coords: [48.81820267460107, 2.307821525524157],
    audio_file: `humpty-dumpty-5.mp3`,
    color: `yellow`,
  },
  {
    chapter: "VI",
    name: "Porc & poivre",
    subname: "Square Pierre Valette",
    text: `
    Allez-vous entrer ? Sortir ? Dans une féline attitude, vous pouvez attendre au milieu du gué. Ni dans
    ce monde, ni dans le Pays des Merveilles, mais entre les deux. Suivez le chat qui vous guidera. Pour
    aller où ? Peu importe.<br /><br />Ce qui compte, c’est avant tout le voyage. Du fond de soi aux confins de
    l’univers, vous seul savez ce que vous trouverez au bout du chemin. Avancez, et vivez votre aventure.      
    `,
    coords: [48.81690540048119, 2.3021154848021257],
    audio_file: `humpty-dumpty-6.mp3`,
    color: `yellow`,
  },
  {
    chapter: "VII",
    name: "Un thé chez les&nbsp;fous",
    subname: "Malaqueen",
    text: `
    On est toutes et tous la folle ou le fou de quelqu’un d’autre. Qu’est-ce que ça veut dire, être normal ?
    Être soi, exprimer son identité. N’est-ce pas l’un des plus beaux actes de courage de sortir du moule,
    d’être soi ? La folie ne serait-elle pas de réprimer sa personnalité ?<br /><br />En ce lieu, demandez à boire un
    thé ou un verre. Vous trouverez bien quelqu’un avec qui célébrer votre non-anniversaire.      
    `,
    coords: [48.81500636966248, 2.2993183135986333],
    audio_file: `humpty-dumpty-7.mp3`,
    color: `yellow`,
  },
  {
    chapter: "VIII",
    name: "Le terrain de croquet de la Reine",
    subname: "Parc Léon Salagnac",
    text: `
    Un carillon vous attire. Laissez votre montre de côté. Perdez-vous dans les allées du Parc. C’est le
    printemps, les fleurs éclosent. Retirez votre costume de lapin blanc trop sérieux. L’odeur de l’herbe
    fait oublier les bruits de la ville.<br /><br />Laissez votre regard dériver sur les aires de jeux. Jouez, jouez. Aux
    cartes, au croquet. Qu’importe à quoi, mais jouez. Laissez rire l’enfant que vous serez toujours.      
    `,
    coords: [48.81305760334674, 2.2930623400790395],
    audio_file: `humpty-dumpty-8.mp3`,
    color: `yellow`,
  },
  {
    chapter: "IX",
    name: "Histoire de la Simili Tortue",
    subname: "Jardin des Nouzeaux",
    text: `
    Que cultive-t-on dans ce jardin ? De la camomille ? Du sucre d’orge ? Y apercevrez-vous une tortue,
    un flamant rose ? La vieille Chélonée est-elle cachée entre deux bacs à compost ?<br /><br />Si vous fermez les
    yeux et y croyez très fort, peut-être trouverez-vous le Griffon qui vous transportera sur son dos et
    s’envolera jusqu’à la prochaine étape de cette promenade enchantée. Sinon, marchez encore un
    peu.      
    `,
    coords: [48.815789443659526, 2.288499646214781],
    audio_file: `humpty-dumpty-9.mp3`,
    color: `yellow`,
  },
  {
    chapter: "X",
    name: "Le quadrille des homards",
    subname: "le Polissoir",
    text: `
    Vos pieds vous rappellent que vous avez bien marché. C’est bientôt fini, mais pas tout à fait. L’envie
    de se reposer vous prend. Ne vous asseyez surtout pas sur cette étrange pierre ! Vestige du fond des
    âges, ce polissoir pourrait vous raconter des milliers d’histoires.<br /><br />Vous êtes délassé ? Prêt à danser le
    quadrille des homards ? Ou à repartir ? Avancez deux fois, balancez, et changez de partenaire.      
    `,
    coords: [48.818064, 2.292581],
    audio_file: `humpty-dumpty-10.mp3`,
    color: `yellow`,
  },
  {
    chapter: "XI",
    name: "Qui a dérobé les tartes ?",
    subname: "Square Marc Lanvin",
    text: `
    Êtes-vous pressé de retrouver le Tribunal de Paix où le Roi de cœur vous jugera ? Vous n’êtes pas en
    retard. Passez par le square. Accroupissez-vous, caressez quelques brins d’herbe. La nature est
    partout. Ici, ni myrtille, ni framboise, ni fraise, ni abricot. Et pourtant…<br /><br />Vous la sentez ? Cette odeur
    de tarte chaude qui sort du four ? Souriez, profitez. Un pas en avant, vers la dernière étape.      
    `,
    coords: [48.81952709963327, 2.298226382952459],
    audio_file: `humpty-dumpty-11.mp3`,
    color: `yellow`,
  },
  {
    chapter: "XII",
    name: "La déposition d'Alice",
    subname: "la Tréso",
    text: `
    N’ayez crainte. Il n’y a personne ici pour vous juger. Vous n’avez qu’à secouer la tête, ouvrir les yeux,
    et vous voici revenu à Malakoff. Il y a de la lumière ?<br /><br />Poussez la porte, entrez.<br /><br />Prenez à boire, une
    part de gâteau. Rien ne vous fera changer de taille. Prenez quelques instants pour vous, pour revenir
    à votre quotidien.<br /><br />Et si vous parliez de votre promenade aux personnes autour de vous ?      
    `,
    coords: [48.82034271046869, 2.301566727130889],
    audio_file: `humpty-dumpty-12.mp3`,
    color: `yellow`,
  },
];
