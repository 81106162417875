export default [
  [0],
  [48.82284774726771, 2.3063242435455327],
  [48.82239566512652, 2.3054820299148564],
  [48.82368126302502, 2.3045593500137334],
  [48.82296429903354, 2.303068041801453],
  [1],
  [48.82260051535201, 2.303298711776734],
  [48.82147029677776, 2.3044091463088994],
  [48.82214489905553, 2.305718064308167],
  [48.82192238726587, 2.3060560226440434],
  [48.82203187731757, 2.3066085577011113],
  [2],
  [48.82271353580767, 2.3079013824462895],
  [48.82182702476791, 2.308813333511353],
  [48.820756833180496, 2.3099935054779057],
  [48.82105705424868, 2.31063723564148],
  [3],
  [48.820110468777365, 2.3087006807327275],
  [48.82003982735376, 2.308614850044251],
  [48.820032763205916, 2.308459281921387],
  [48.81971840761915, 2.307214736938477],
  [48.81865170318013, 2.307016253471375],
  [48.81801590828019, 2.307590246200562],
  [4],
  [48.81770154004466, 2.3078852891922],
  [48.817157572373205, 2.30714499950409],
  [48.817422492431085, 2.3062330484390263],
  [48.81745428274393, 2.3056751489639287],
  [48.816567678681416, 2.3050743341445927],
  [48.81762029849467, 2.3029124736785893],
  [48.81695623219285, 2.3021829128265385],
  [5],
  [48.81578067316223, 2.300886102707649],
  [48.81487647391019, 2.2992626061029764],
  [6],
  [48.81551150437213, 2.2984653711318974],
  [48.81470611235327, 2.2968453168869023],
  [48.81410205984794, 2.2955363988876347],
  [48.81300344277614, 2.293079495429993],
  [7],
  [48.81235697839309, 2.293487191200257],
  [48.81233578269844, 2.293433547019959],
  [48.81225099983015, 2.293358445167542],
  [48.813148277915694, 2.2908264398574834],
  [48.814310476447, 2.2879564762115483],
  [48.81441645065663, 2.287940382957459],
  [48.81451182725374, 2.287892103195191],
  [48.81505229121005, 2.2891044616699223],
  [48.815666929412636, 2.2885572910308842],
  [8],
  [48.81578703025028, 2.2884821891784672],
  [48.81635927146624, 2.289758920669556],
  [48.81616146042661, 2.2900271415710454],
  [48.81707279765899, 2.291958332061768],
  [48.81744015371849, 2.2915184497833256],
  [48.817991182760075, 2.292612791061402],
  [9],
  [48.8169279738572, 2.2940987348556523],
  [48.81813953569789, 2.2964429855346684],
  [48.81923097595694, 2.297848463058472],
  [48.8192557008655, 2.2982400655746464],
  [10],
  [48.81920625103615, 2.299720644950867],
  [48.81922391169508, 2.3009330034255986],
  [48.819764324850304, 2.301292419433594],
  [48.81977138903596, 2.301549911499024],
  [48.82005748771899, 2.301721572875977],
  [11],
];
