<template>
  <div>
    <!-- revealed_balises {{ $root.revealed_balises }}<br />
    openable_balises
    {{ $root.openable_balises }} -->

    <NavBar :balises="balises" @showWelcomeModal="$emit('showWelcomeModal')" />
    <!-- <div class="_loader" v-if="is_loading" key="loader">Chargement…</div> -->
    <div
      id="map"
      :class="{
        'is--hidden': opened_balise,
      }"
    />

    <GPS
      v-if="start_gps"
      :balises="balises"
      @newGPSLocation="newGPSLocation"
      @openBalise="openBalise"
      ref="GPS"
    />

    <BaliseModal
      v-if="opened_balise"
      :balise="opened_balise"
      :index="balise_modal_opened_for_balise"
      :key="JSON.stringify(opened_balise.coords)"
      @close="balise_modal_opened_for_balise = false"
      :style="`--color-balise: var(--color-${opened_balise.color})`"
    />
  </div>
</template>
<script>
import BaliseModal from "./BaliseModal.vue";
import NavBar from "./NavBar.vue";
import GPS from "./GPS.vue";

import malakoff_polygon_coords from "../datasets/malakoff_polygon";
import balises from "../datasets/balises";
import layers from "../datasets/layers";
import parcours from "../datasets/parcours";

export default {
  props: {},
  components: {
    BaliseModal,
    NavBar,
    GPS,
  },
  data() {
    return {
      map: undefined,
      balises_local: "",
      balises,
      balise_modal_opened_for_balise: false,

      publicPath: process.env.BASE_URL,

      l_parcours: undefined,
      l_balises: [],

      l_all_positions: undefined,

      is_loading: true,
      start_gps: false,
    };
  },
  created() {},
  mounted() {
    this.initMap().then(() => {
      this.is_loading = false;
      this.$nextTick(() => {
        this.start_gps = true;
      });
    });
  },
  beforeDestroy() {},
  watch: {
    balises_local() {
      this.initMap();
    },
    "$root.revealed_balises"() {
      this.showParcours();
      this.showBalises();
    },
    "$root.openable_balises"() {
      // TODO reveal balise depending on openable
      // reveal openable + 1;
      if (this.$root.openable_balises.length === 0) return;
      this.$root.revealed_balises.push(
        this.$root.openable_balises[this.$root.openable_balises.length - 1] + 1
      );
    },
    "$root.gps_locations": {
      handler() {
        this.unlockBalises();
        this.showPosition();
        this.checkProximity();
      },
    },
    "$root.is_narrow_screen": {
      handler() {
        if (!this.map || !this.map.zoomControl) return;

        if (this.$root.is_narrow_screen) {
          this.map.zoomControl.setPosition("topright");
        } else {
          this.map.zoomControl.setPosition("bottomright");
        }
      },
      immediate: true,
    },
  },
  computed: {
    opened_balise() {
      if (this.balise_modal_opened_for_balise === false) {
        // if (this.l_parcours) this.l_parcours.resume();
        return false;
      }
      // if (this.l_parcours) this.l_parcours.pause();
      return this.balises[this.balise_modal_opened_for_balise];
    },
  },
  methods: {
    initMap() {
      return new Promise((resolve) => {
        console.log(`initMap`);
        /*global L, protomaps*/

        if (this.map && this.map.remove) {
          this.map.off();
          this.map.remove();
        }

        this.map = L.map("map", {
          zoomSnap: 0.5,
          maxZoom: 18,
          zoom: 15,
          minZoom: 13.5,

          detectRetina: false,
          renderer: L.svg({ padding: 1 }),
        });
        let lang = ["name:fr", "name"];

        this.map.on("contextmenu", (evt) => {
          this.$refs.GPS.gotNewPosition({
            latitude: evt.latlng.lat,
            longitude: evt.latlng.lng,
          });
          // this.$root.gps_locations.push([evt.latlng.lat, evt.latlng.lng]);
        });

        // credits: https://github.com/turban/Leaflet.Mask
        L.Mask = L.Polygon.extend({
          options: {
            stroke: false,
            // color: "#2D2841",
            color: "var(--color-cream)",
            fillOpacity: 1,
            clickable: false,
            interactive: false,
            outerBounds: new L.LatLngBounds([-90, -360], [90, 360]),
          },

          initialize: function (latLngs, options) {
            var outerBoundsLatLngs = [
              this.options.outerBounds.getSouthWest(),
              this.options.outerBounds.getNorthWest(),
              this.options.outerBounds.getNorthEast(),
              this.options.outerBounds.getSouthEast(),
            ];
            L.Polygon.prototype.initialize.call(
              this,
              [outerBoundsLatLngs, latLngs],
              options
            );
          },
        });
        L.mask = function (latLngs, options) {
          return new L.Mask(latLngs, options);
        };

        const p = new protomaps.PMTiles(
          `${this.publicPath}leaflet/tiles.pmtiles`
        );

        console.log(`will load pmtiles`);

        // eslint-disable-next-line
        p.metadata().then((m) => {
          console.log(`loaded pmtiles`);
          // let bounds_str = m.bounds.split(",");
          // let map_bounds = [
          //   [+bounds_str[1], +bounds_str[0]],
          //   [+bounds_str[3], +bounds_str[2]],
          // ];
          // {
          //     dataLayer: "buildings",
          //     symbolizer: new protomaps.PolygonSymbolizer({
          //       fill: "#aaa",
          //     }),
          //   },
          //   {
          //     dataLayer: "roads",
          //     symbolizer: new protomaps.LineSymbolizer({
          //       color: "#aaa",
          //     }),
          //   },

          let result = protomaps.json_style({ layers }, {});

          // eslint-disable-next-line
          let label_rules = [
            {
              dataLayer: "roads",
              symbolizer: new protomaps.LineLabelSymbolizer({
                label_props: lang,
                font: "400 14px Faune",
                fill: "hsl(0, 0%, 57%)",
              }),
            },
          ];

          protomaps
            .leafletLayer({
              attribution:
                '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>',
              url: p,
              paint_rules: result.paint_rules,
              label_rules,
              maxBoundsViscosity: 0.5,
              renderer: L.svg({ padding: 1 }),
            })
            .addTo(this.map);

          this.map.zoomControl.setPosition("bottomright");

          var malakoff_polygon = L.polygon(malakoff_polygon_coords);
          // this.map.fitBounds(malakoff_polygon.getBounds());
          this.map.setMaxBounds(malakoff_polygon.getBounds().pad(0.5));
          this.map.setView(
            [48.821118027641496, 2.310624725281102],
            this.map.getZoom()
          );

          L.mask(malakoff_polygon_coords).addTo(this.map);

          this.showParcours();
          this.showBalises();
          this.unlockBalises();
          this.showPosition();
          this.checkProximity();

          console.log(`mapInit`);

          return resolve();
        });
      });
    },
    newGPSLocation({ latitude, longitude }) {
      console.log(`newGPSLocation`);
      this.$root.gps_locations.push([latitude, longitude]);
      this.map.setView([latitude, longitude], this.map.getZoom());
    },
    showParcours() {
      if (this.map) {
        console.log(`showParcours`);
        if (this.l_parcours) this.l_parcours.remove();

        let parcours_interrompu = [];
        parcours.some((p) => {
          if (p.length === 2) {
            parcours_interrompu.push(p);
            return false;
          } else if (p.length === 1) {
            // check if corresponding is revealed
            if (!this.$root.openable_balises.includes(p[0])) {
              return true;
            }
          }
        });

        this.l_parcours = L.polyline(parcours_interrompu, {
          color: "var(--color-yellow)",
          // smoothFactor: 10,
          dashArray: [10, 10],
          weight: 5,
          interactive: false,
        }).addTo(this.map);
      }
    },
    unlockBalises() {
      if (this.map) {
        console.log(`unlockBalises`);

        if (this.$root.mode === "short") return;

        // this.$root.openable_balises = [];

        // check distance between balises and position
        this.$root.revealed_balises.map((rb) => {
          this.$root.gps_locations.map((loc) => {
            if (this.$root.openable_balises.includes(rb)) return;

            const b = this.balises[rb];
            if (!b) return;

            const latlng1 = new L.latLng(b.coords);
            const latlng2 = new L.latLng(loc);
            const distance = latlng1.distanceTo(latlng2);

            console.log(`unlockBalises distance ${distance}`);
            if (distance < 50) {
              this.$root.openable_balises.push(rb);
            }
          });
        });
      }
    },
    showPosition() {
      if (this.map) {
        console.log(`showPosition`);
        if (this.l_all_positions) this.l_all_positions.remove();
        if (this.l_current_position) this.l_current_position.remove();

        if (this.$root.gps_locations.length > 0) {
          this.l_all_positions = L.polyline(this.$root.gps_locations, {
            color: "var(--color-red)",
            opacity: 0.5,
            // smoothFactor: 10,
            // dashArray: [10, 10],
            weight: 5,
            interactive: false,
          }).addTo(this.map);

          const latest_position =
            this.$root.gps_locations[this.$root.gps_locations.length - 1];

          this.l_current_position = L.circle(latest_position, {
            fillColor: `var(--color-red)`,
            weight: 0,
            fillOpacity: 1,
            radius: 22,
          }).addTo(this.map);
        }
      }
    },
    checkProximity() {
      if (this.map && this.$root.gps_locations.length > 0) {
        console.log(`checkProximity`);
        const loc =
          this.$root.gps_locations[this.$root.gps_locations.length - 1];

        this.$root.balise_in_range = this.$root.revealed_balises.find((rb) => {
          const b = this.balises[rb];
          if (!b) return;
          const latlng1 = new L.latLng(b.coords);
          const latlng2 = new L.latLng(loc);
          const distance = latlng1.distanceTo(latlng2);

          if (distance < 50) {
            return true;
          }
        });
      }
    },
    showBalises() {
      if (this.map) {
        if (this.l_balises.length > 0)
          this.l_balises.map((l_b) => l_b.remove());
        this.balises.map((b, index) => {
          if (!this.$root.revealed_balises.includes(index)) return;

          let color = `var(--color-${b.color})`;
          if (!this.$root.openable_balises.includes(index)) {
            color = `var(--color-purple)`;
          }

          this.l_balises.push(
            L.circle(b.coords, {
              fillColor: color,
              weight: 0,
              fillOpacity: 0.8,
              radius: 50,
            })
              .on("click", () => this.openBalise(index))
              .addTo(this.map),
            L.marker(b.coords, {
              fillColor: `var(--color-${b.color})`,
              weight: 0,
              interactive: false,
              fillOpacity: 0.8,
              icon: L.divIcon({
                html: b.chapter,
                iconSize: L.point(25, 25),
              }),
            }).addTo(this.map)
            // L.circle(b.coords, {
            //   fillColor: `var(--color-${b.color})`,
            //   weight: 0,
            //   fillOpacity: 1,
            //   radius: 20,
            // })
            //   .on("click", () => this.openBalise(index))
            //   .addTo(this.map)
          );
        });
      }
    },

    openBalise(index) {
      if (!this.$root.openable_balises.includes(index)) return false;
      this.balise_modal_opened_for_balise = index;
    },
  },
};
</script>
<style lang="scss" scoped>
#map {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  z-index: 1;
  background: #ebe6e0;
  overflow: hidden;
  // image-rendering: pixelated;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &.is--hidden {
    opacity: 0;
  }

  ::v-deep {
    button {
      touch-action: none;
    }

    canvas {
      // image-rendering: pixelated;
    }
    .leaflet-div-icon {
      background: transparent;
      border: none;
      font-weight: 500;
      font-size: inherit;
      // color: var(--color-darker-gray);
      display: flex;
      font-size: 1rem;
      justify-content: center;
      align-content: center;
    }
  }
}

textarea {
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;

  z-index: 1;
}
</style>
