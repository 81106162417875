export default [
  // https://protomaps.com/editor/#16/25.0667/121.5304
  {
    id: "natural",
    type: "fill",
    source: "protomaps",
    "source-layer": "natural",
    paint: {
      "fill-color": "#b1ebb3",
      "fill-opacity": 0.7,
    },
  },
  {
    id: "water",
    type: "fill",
    source: "protomaps",
    "source-layer": "water",
    paint: {
      "fill-color": "#268bd2",
      "fill-opacity": 0.5,
    },
  },
  // {
  //   id: "buildings",
  //   type: "fill",
  //   source: "protomaps",
  //   "source-layer": "buildings",
  //   paint: {
  //     "fill-color": "#C3BEC3",
  //     "fill-opacity": 0.2,
  //   },
  // },
  {
    id: "roads",
    type: "line",
    source: "protomaps",
    "source-layer": "roads",
    paint: {
      "line-color": "white",
      "line-opacity": 0.5,
      "line-width": [
        "interpolate",
        ["exponential", 1.6],
        ["zoom"],
        7,
        0,
        7.5,
        0.5,
        20,
        32,
      ],
    },
  },
  // {
  //   dataLayer: "landuse",
  //   symbolizer: new protomaps.PolygonSymbolizer({
  //     pattern: halftone,
  //   }),
  //   filter: (f) => {
  //     return f.leisure == "park";
  //   },
  // },
  {
    id: "transit_railway",
    type: "line",
    source: "protomaps",
    "source-layer": "transit",
    filter: ["all", ["==", "pmap:kind", "railway"]],
    paint: {
      "line-color": "#9B9B9B",
      "line-dasharray": [10, 2],
    },
  },

  // {
  //   id: "boundaries",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "boundaries",
  //   paint: {
  //     "line-color": "blue",
  //     "line-width": 1,
  //     "line-dasharray": [5, 2],
  //   },
  // },
  // {
  //   id: "roads_bridges_other_casing",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "other"]],
  //   paint: {
  //     "line-color": "orange",
  //     "line-gap-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       14,
  //       0,
  //       14.5,
  //       0.5,
  //       20,
  //       12,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_other",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "other"]],
  //   paint: {
  //     "line-color": "orange",
  //     "line-dasharray": [2, 1],
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       14,
  //       0,
  //       14.5,
  //       0.5,
  //       20,
  //       12,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_minor_casing",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "minor_road"]],
  //   paint: {
  //     "line-color": "blue",
  //     "line-gap-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       12,
  //       0,
  //       12.5,
  //       0.5,
  //       20,
  //       32,
  //     ],
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       12,
  //       0,
  //       12.5,
  //       1,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_minor",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "minor_road"]],
  //   paint: {
  //     "line-color": "blue",
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       12,
  //       0,
  //       12.5,
  //       0.5,
  //       20,
  //       32,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_medium_casing",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "medium_road"]],
  //   paint: {
  //     "line-color": "yellow",
  //     "line-gap-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       7,
  //       0,
  //       7.5,
  //       0.5,
  //       20,
  //       32,
  //     ],
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       10,
  //       0,
  //       10.5,
  //       1,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_medium",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "medium_road"]],
  //   paint: {
  //     "line-color": "red",
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       7,
  //       0,
  //       7.5,
  //       0.5,
  //       20,
  //       32,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_major_casing",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "major_road"]],
  //   paint: {
  //     "line-color": "gray",
  //     "line-gap-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       7,
  //       0,
  //       7.5,
  //       0.5,
  //       19,
  //       32,
  //     ],
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       9,
  //       0,
  //       9.5,
  //       1,
  //     ],
  //   },
  // },
  // {
  //   id: "roads_bridges_major",
  //   type: "line",
  //   source: "protomaps",
  //   "source-layer": "roads",
  //   filter: ["all", [">", "pmap:level", 0], ["==", "pmap:kind", "major_road"]],
  //   paint: {
  //     "line-color": "purple",
  //     "line-width": [
  //       "interpolate",
  //       ["exponential", 1.6],
  //       ["zoom"],
  //       7,
  //       0,
  //       7.5,
  //       0.5,
  //       19,
  //       32,
  //     ],
  //   },
  // },
  {
    id: "landuse_park",
    type: "fill",
    source: "protomaps",
    "source-layer": "landuse",
    filter: ["any", ["==", "pmap:kind", "park"], ["==", "landuse", "cemetery"]],
    paint: {
      "fill-color": "#b1ebb3",
      "fill-opacity": 0.7,
    },
  },
  {
    id: "landuse_school",
    type: "fill",
    source: "protomaps",
    "source-layer": "landuse",
    filter: ["any", ["==", "pmap:kind", "school"]],
    paint: {
      "fill-color": "#BEB9BE",
      "fill-opacity": 1,
    },
  },
  // {
  //   id: "landuse_pedestrian",
  //   type: "fill",
  //   source: "protomaps",
  //   "source-layer": "landuse",
  //   filter: ["any", ["==", "highway", "footway"]],
  //   paint: {
  //     "fill-color": "#ff00ff",
  //   },
  // },
];
