<template>
  <div
    class="m_baliseModal"
    @click.self="closeModal"
    :class="{
      'is--showingModal': show_content,
    }"
    :style="`--reveal-time: ${reveal_time}s`"
  >
    <transition name="slideup">
      <div class="m_baliseModal--content" v-if="show_content">
        <button type="button" class="closeButton" @click="closeModal">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="23.7px"
            height="23.7px"
            viewBox="0 0 23.7 23.7"
            style="stroke: currentColor; stroke-width: 2px"
            xml:space="preserve"
          >
            <line class="st0" x1="1.1" y1="1.1" x2="22.6" y2="22.6" />
            <line class="st0" x1="22.6" y1="1.1" x2="1.1" y2="22.6" />
          </svg>
        </button>

        <h3
          v-html="`Chapitre ${balise.chapter} • ${balise.subname}`"
          class="_chapter"
        />
        <h1 v-html="balise.name" />
        <hr />
        <audio
          :disabled="!balise.audio_file"
          :src="`${publicPath}sons/${balise.audio_file}`"
          controls
          ref="audioPlayer"
        />
        <p v-html="balise.text" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    balise: Object,
    index: Number,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      show_content: false,

      reveal_time: 0.5,
    };
  },
  created() {},
  mounted() {
    if (!this.$root.opened_balises.includes(this.index))
      this.$root.opened_balises.push(this.index);

    setTimeout(() => {
      this.show_content = true;

      /*global Plyr*/
      this.$nextTick(() => {
        new Plyr(this.$refs.audioPlayer);
      });
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    closeModal() {
      this.show_content = false;
      setTimeout(() => {
        this.$emit("close");
      }, this.reveal_time * 2 * 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.m_baliseModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow: auto;
  pointer-events: none;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 200px);
    position: fixed;
    top: -200px;
    left: 0;
    color: var(--color-cream);
    background: linear-gradient(transparent 0%, currentColor 20%);

    opacity: 0;

    transition: opacity 5s cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: var(--reveal-time);
    transition-delay: var(--reveal-time);
  }

  &.is--showingModal {
    pointer-events: auto;
    &::before {
      transition-delay: 0s;
      opacity: 0.8;
    }
  }
}

.m_baliseModal--content {
  position: relative;
  min-height: calc(100% - 40px);

  max-width: 480px;
  margin: 10vh auto 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  // border-radius: 12px;
  padding: 40px 20px;

  background-color: var(--color-teal);
  background-color: var(--color-balise);
  color: #000;

  .closeButton {
    position: absolute;
    right: 1em;
    top: 1em;

    margin-left: auto;
  }
}

._chapter {
  margin: 0;
  margin-bottom: calc(var(--spacing) * 1);
  text-align: center;
  font-size: 1rem;
}

h1 {
  font-size: clamp(2.5rem, 5vw, 3rem);
  text-align: center;
  margin-top: 0;
  margin-bottom: calc(var(--spacing) * 2);
}

audio {
  display: block;
  margin: 1.5em 0;
  width: 100%;
  border-radius: 25px;
  background-color: transparent;
}

.slideup-enter-active,
.slideup-leave-active {
  transform: translateY(0);
  transition: transform var(--reveal-time);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.slideup-enter,
.slideup-leave-to {
  // opacity: 0;
  transform: translateY(100%);
}

.slideup-leave-active {
  transition-timing-function: cubic-bezier(0.19, 0, 0.22, 0);
}
</style>
