<template>
  <div class="home">
    <WelcomeModal
      v-if="$root.show_welcome_modal"
      @close="$root.show_welcome_modal = false"
    />
    <FullMap v-else @showWelcomeModal="$root.show_welcome_modal = true" />
  </div>
</template>

<script>
// @ is an alias to /src
import WelcomeModal from "@/components/WelcomeModal.vue";
import FullMap from "@/components/FullMap.vue";

export default {
  name: "HomeView",
  components: {
    WelcomeModal,
    FullMap,
  },
  data() {
    return {};
  },
};
</script>
