<template>
  <nav class="_nav">
    <div class="_nav--topbar">
      <div>
        <h1>Alice à Malakoff</h1>
        <h2>Le parcours Humpty Dumpty</h2>
      </div>
      <div class="_btns">
        <button
          type="button"
          class="is--small"
          @click="$emit('showWelcomeModal')"
        >
          ← retour
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    balises: Array,
  },
  components: {},
  data() {
    return {
      show_menu: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
._nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  // margin: 2rem;
  pointer-events: none;

  ._nav--topbar {
    // display: flex;
    // justify-content: space-between;
    padding: 3vmin 8vmin 3vmin 4vmin;

    h1 {
      position: relative;
      font-size: clamp(2rem, 5vw, 4rem);
      margin: 0;
    }
    h2 {
      font-size: clamp(1.1rem, 2.5vw, 2rem);
      margin: 0;
    }

    button {
      pointer-events: auto;
    }
  }
}

._btns {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin: calc(var(--spacing) / 1) 0;
  gap: calc(var(--spacing) / 2);
}
</style>
