<template>
  <div
    class="m_gps"
    :class="{
      'is--showingList': open_menu,
    }"
  >
    <div class="_balises" v-if="gps_status === 'ok'">
      <div class="_balises--list" v-if="open_menu">
        <div>
          <ol>
            <li
              v-for="(balise, index) in balises"
              :key="index"
              :style="`--color-balise: var(--color-${balise.color})`"
            >
              <button
                type="button"
                @click="$emit('openBalise', index)"
                class="_inlineCtn"
                :disabled="!$root.openable_balises.includes(index)"
              >
                <span class="_chapter" v-html="balise.chapter" />
                <span class="_name" v-html="balise.name" />
              </button>
            </li>
          </ol>
        </div>
      </div>

      <button
        type="button"
        v-if="
          $root.balise_in_range >= 0 &&
          !$root.opened_balises.includes($root.balise_in_range)
        "
        class="_balises--playCurrent"
        @click="$emit('openBalise', $root.balise_in_range)"
      >
        <span>Écoutez</span>
        <span class="_inlineCtn">
          <span
            class="_chapter"
            v-html="balises[$root.balise_in_range].chapter"
          />
          <span class="_name" v-html="balises[$root.balise_in_range].name" />
        </span>
      </button>

      <button
        v-else
        class="_balises--showBtn"
        type="button"
        @click="open_menu = !open_menu"
      >
        <template v-if="$root.openable_balises.length < balises.length">
          <span class="_tri" :class="{ 'is--active': open_menu }">▼</span>
          Rendez-vous à l’étape
          {{ next_balise.chapter }}&nbsp;:<br />{{
            next_balise.subname
          }}&nbsp;<span style="color: var(--color-purple)">⬤</span>
        </template>
        <template v-else>
          Rapprochez-vous de n’importe quel
          <span style="color: var(--color-yellow)">⬤</span><br />pour l’écouter
          !
        </template>
      </button>
    </div>
    <div class="">
      <div class="_gpsStatus">
        <div class="_loader" v-if="gps_status === 'searching'" key="loader">
          Chargement…
        </div>
        <button
          v-else-if="gps_status_text"
          type="button"
          @click="geoFindMe"
          key="gpsbutton"
        >
          <span v-html="gps_status_text" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import malakoff_polygon_coords from "../datasets/malakoff_polygon";

export default {
  props: {
    balises: Array,
  },
  components: {},
  data() {
    return {
      open_menu: false,

      gps_status: "",
      gps_status_text: false,
    };
  },
  created() {},
  mounted() {
    this.geoFindMe();

    // setTimeout(() => {
    //   this.geoFindMe();
    // }, 5000);
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    next_balise() {
      return this.balises[
        this.$root.revealed_balises[this.$root.revealed_balises.length - 1]
      ];
    },
  },
  methods: {
    geoFindMe() {
      /*global L*/
      const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        this.gotNewPosition({ latitude, longitude });
      };
      const error = (err) => {
        this.gps_status = "err";
        this.gps_status_text = `Impossible d’accéder à la géolocalisation de votre appareil.<br>
          <small>Avez-vous bien autorisé ce site à y accéder, et les préférences de votre navigateur ?
          Rechargez ensuite cette page pour réessayer, et contactez-nous si besoin : 
          <a href="mailto:hello@louiseveillard.com" target="_blank">hello@louiseveillard.com</a><br>
          <i>Code d’erreur = ${err.message}</i></small>`;
      };
      if (!navigator.geolocation) {
        this.gps_status = "err";
        this.gps_status_text =
          "La géolocalisation n’est pas prise en charge par votre navigateur.";
      } else {
        this.gps_status = "searching";
        this.gps_status_text = "Localisation en cours…";

        setTimeout(() => {
          navigator.geolocation.getCurrentPosition(success, error, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        }, 500);
      }
    },
    gotNewPosition({ latitude, longitude }) {
      console.log(`gotNewPosition ${JSON.stringify({ latitude, longitude })}`);

      var malakoff_polygon = L.polygon(malakoff_polygon_coords);
      const is_inside = malakoff_polygon.contains({
        lat: latitude,
        lng: longitude,
      });

      if (is_inside) {
        this.gps_status = "ok";
        this.gps_status_text = `Vous êtes ici <span style="color: var(--color-red)">⬤</span>
          <br><small>(mettre à jour en cliquant)</small>
          `;
        this.$emit("newGPSLocation", { latitude, longitude });
      } else {
        this.gps_status = "not_in_malakoff";
        this.gps_status_text = `Il semble que vous n’êtes pas à Malakoff&nbsp;!<br>Allez-y pour commencer la balade&nbsp;:)<br><small>(mettre à jour en cliquant)</small>
            `;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.m_gps {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: calc(var(--spacing) * 1) calc(var(--spacing) * 1);

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: calc(var(--spacing) / 2);

  pointer-events: none;
  touch-action: none;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 200px);
    position: fixed;
    top: -200px;
    left: 0;
    z-index: -1;
    color: var(--color-cream);
    background: linear-gradient(transparent 0%, currentColor 20%);

    opacity: 0;

    transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
  ::v-deep {
    button {
      touch-action: none;
    }
  }

  &.is--showingList {
    &::before {
      transition-delay: 0s;
      opacity: 0.8;
    }
  }

  > * {
    color: var(--color-red);
    background: white;
    max-width: 480px;
    border-radius: 1em;

    pointer-events: auto;
  }

  ._balises {
    flex: 0 1 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    ._balises--list {
      position: relative;
      flex: 1 1 auto;
      overflow: auto;
    }

    ._balises--showBtn,
    ._balises--playCurrent {
      flex: 0 0 auto;
    }
  }

  button {
    background: none;
    color: inherit;
  }
}

._balises--playCurrent,
._balises--showBtn,
._gpsStatus {
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;

  color: var(--color-darker-gray);
  margin: calc(var(--spacing) / 2);

  // padding: 0;
  li {
    // padding: 0;
    // color: var(--color-yellow);
    // margin: 0 calc(var(--spacing) / 2);
    &::marker {
      display: inline-block;
      background: var(--color-balise);
      color: var(--color-yellow);
    }

    button {
      width: 100%;

      &:hover,
      &:focus-visible,
      &:active {
        &:not([disabled]) {
          background: var(--color-balise);
        }
      }

      &[disabled] {
        opacity: 0.35;
      }
    }
  }
}

._inlineCtn {
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
  color: black;
}
.is--striked {
  text-decoration-line: line-through;
}

._chapter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-yellow);
  width: 2em;
  height: 2em;
  margin: 0 calc(var(--spacing) / 2);
  border-radius: 50%;
  text-align: center;

  flex: 0 0 auto;
}
._name {
  margin-bottom: 6px;
}
._tri {
  color: var(--color-gray);
  display: inline-block;
  transform: rotate(-90deg);
  font-size: var(--fs-small);

  &.is--active {
    transform: rotate(-180deg);
  }
}

._loader {
  margin: 0;
}
</style>
